var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('maintenance-page',{attrs:{"page-title":"Bill Definition","page-icon":"mdi-account-multiple-outline","collection-name":"billDefinition","done-redirect-url":_vm.doneRedirectUrl,"buttonFunctions":{ deleteBillDefinition: _vm.deleteBillDefinition },"buttonsLeftItems":_vm.topButtons},on:{"input":_vm.formUpdated},scopedSlots:_vm._u([{key:"after-form",fn:function(){return [(_vm.billData)?_c('div',[_c('bill-canvas',{attrs:{"pages":_vm.billData.pages,"page-urls":_vm.pageUrls,"bill-profile-text-block":_vm.billProfileTextBlock,"bill-definition-text-block":_vm.billDefinitionTextBlock,"bill-vendor-text-block":_vm.billVendorTextBlock,"bill-private-note-text-block":_vm.billPrivateNoteTextBlock,"bill-invoice-note-text-block":_vm.billInvoiceNoteTextBlock,"data-fields":_vm.dataFields,"saveCounter":_vm.saveCounter},on:{"text-block-selected":_vm.textBlockSelected}}),_c('page-footer',[_c('toolbar',{attrs:{"left-items":_vm.buttonsLeftItems,"button-functions":{
                            interpretBill: _vm.interpretBill,
                            saveBillProfileTextBlock: _vm.saveBillProfileTextBlock,
                            saveBillDefinitionTextBlock: _vm.saveBillDefinitionTextBlock,
                            saveBillDefinitionIsPaymentReference: _vm.saveBillDefinitionIsPaymentReference,
                            saveBillVendorTextBlock: _vm.saveBillVendorTextBlock,
                            saveBillPrivateNoteTextBlock: _vm.saveBillPrivateNoteTextBlock,
                            saveBillInvoiceNoteTextBlock: _vm.saveBillInvoiceNoteTextBlock,
                            deleteBillDefinition: _vm.deleteBillDefinition,
                        }},on:{"input":_vm.formUpdated}})],1)],1):_vm._e()]},proxy:true}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }