<template>
    <div>
        <maintenance-page
            page-title="Bill Definition"
            page-icon="mdi-account-multiple-outline"
            collection-name="billDefinition"
            :done-redirect-url="doneRedirectUrl"
            @input="formUpdated"
            :buttonFunctions="{ deleteBillDefinition }"
            :buttonsLeftItems="topButtons"
        >
            <template v-slot:after-form>
                <div v-if="billData">
                    <bill-canvas
                        :pages="billData.pages"
                        :page-urls="pageUrls"
                        @text-block-selected="textBlockSelected"
                        :bill-profile-text-block="billProfileTextBlock"
                        :bill-definition-text-block="billDefinitionTextBlock"
                        :bill-vendor-text-block="billVendorTextBlock"
                        :bill-private-note-text-block="billPrivateNoteTextBlock"
                        :bill-invoice-note-text-block="billInvoiceNoteTextBlock"
                        :data-fields="dataFields"
                        :saveCounter="saveCounter"
                    />
                    <page-footer>
                        <toolbar
                            :left-items="buttonsLeftItems"
                            :button-functions="{
                                interpretBill,
                                saveBillProfileTextBlock,
                                saveBillDefinitionTextBlock,
                                saveBillDefinitionIsPaymentReference,
                                saveBillVendorTextBlock,
                                saveBillPrivateNoteTextBlock,
                                saveBillInvoiceNoteTextBlock,
                                deleteBillDefinition,
                            }"
                            @input="formUpdated"
                        />
                    </page-footer>
                </div>
            </template>
        </maintenance-page>
    </div>
</template>

<script>
import MaintenancePage from '@/components/pages/Maintenance';
import BillCanvas from '@/views/admin/maintenance/bill/BillCanvas.vue';

import api from '@/api';

const buttonsLeftItems = [
    {
        id: 'interpretBill',
        label: 'Interpret Bill',
        size: 'x-small',
        clickFunctionName: 'interpretBill',
    },
    {
        id: 'saveBillProfileTextBlock',
        label: 'Save Profile',
        size: 'x-small',
        color: 'purple',
        clickFunctionName: 'saveBillProfileTextBlock',
    },
    {
        id: 'saveBillDefinitionTextBlock',
        label: 'Save Definition',
        size: 'x-small',
        color: 'blue',
        clickFunctionName: 'saveBillDefinitionTextBlock',
    },
    {
        id: 'saveBillDefinitionIsPaymentReference',
        label: 'Definition Is Payment Ref',
        size: 'x-small',
        color: 'blue',
        clickFunctionName: 'saveBillDefinitionIsPaymentReference',
    },
    {
        id: 'saveBillVendorTextBlock',
        label: 'Save Vendor',
        size: 'x-small',
        color: 'green',
        clickFunctionName: 'saveBillVendorTextBlock',
    },
    {
        id: 'saveBillPrivateNoteTextBlock',
        label: 'Save Private Note',
        size: 'x-small',
        color: 'orange',
        clickFunctionName: 'saveBillPrivateNoteTextBlock',
    },
    {
        id: 'saveBillInvoiceNoteTextBlock',
        label: 'Save Invoice Note',
        size: 'x-small',
        color: 'cyan',
        clickFunctionName: 'saveBillInvoiceNoteTextBlock',
    },
];

const topButtons = [
    {
        id: 'deleteBillDefinition',
        preset: 'delete',
        clickFunctionName: 'deleteBillDefinition',
        name: 'Bill Definition',
        confirm: 'Are you sure you want to delete this definition?'
    },
]

export default {
    name: 'BillDefinitionDetail',
    components: { MaintenancePage, BillCanvas },
    data: () => ({
        id: null,
        buttonsLeftItems,
        topButtons,
        doneRedirectUrl: '/auth/admin/billdefinition?isHistory=true',
        billData: null,
        fields: [],
        fieldNames: [],
        pageUrls: [],
        billProfileTextBlock: null,
        billDefinitionTextBlock: null,
        billVendorTextBlock: null,
        billPrivateNoteTextBlock: null,
        billInvoiceNoteTextBlock: null,
        dataFields: [],
        formModel: null,
        credentialClientConfig: null,
        selectedTextArea: null,
        saveCounter: 0,
    }),
    methods: {
        async deleteBillDefinition() {
            const result = await api.post(this.$store, `billdefinition/delete`, {
                id: this.id,
            });
            if (result && result.data && result.data.success) {
                await this.$router.push(this.doneRedirectUrl);
            } else {
                const errorMessage = result && result.data ? result.data.errorMessage : 'Unknown Error';
                await this.$store.dispatch('setError', errorMessage);
            }
        },
        async formUpdated(formModel) {
            if (formModel && this.formModel) {
                console.log('formUpdated', formModel);
                console.log('masterfile changed?', this.formModel.masterFile !== formModel.masterFile);
                if (this.formModel.masterFile !== formModel.masterFile) {
                    await this.interpretBill(formModel);
                }
            }
            this.formModel = formModel;
        },
        log(formModel) {
            console.log('form updated', formModel);
        },
        processResultData(result) {
            if (result.data && result.data.billDefinition) {
                const { billDefinition, pageUrls } = result.data;

                this.pageUrls = pageUrls;
                this.billData = billDefinition.billData;
                this.fields = billDefinition.fields;
                this.billProfileTextBlock = billDefinition.billProfileTextBlock;
                this.billDefinitionTextBlock = billDefinition.billDefinitionTextBlock;
                this.billVendorTextBlock = billDefinition.billVendorTextBlock;
                this.billPrivateNoteTextBlock = billDefinition.billPrivateNoteTextBlock;
                this.billInvoiceNoteTextBlock = billDefinition.billInvoiceNoteTextBlock;

                console.log('loaded bprofid', this.billProfileTextBlock);
                if (!this.billData) {
                    this.fieldNames = [];
                    this.dataFields = [];
                } else {
                    this.fieldNames = Object.keys(this.fields);
                    this.dataFields = this.fieldNames.map((oneFieldName) => {
                        const field = this.fields[oneFieldName];
                        const { vertices, pageIndex, rawValue: text } = field;
                        return { fieldName: oneFieldName, vertices, pageIndex, text };
                    });
                }
            }
            this.saveCounter++;
        },
        saveBillProfileTextBlock() {
            this.billProfileTextBlock = this.selectedTextArea;
            this.saveTextBlock('billProfile');
        },
        saveBillDefinitionTextBlock() {
            console.log('src/views/admin/maintenance/bill/billDefinition/Detail.vue saveBillDefinitionTextBlock 1');
            this.billDefinitionTextBlock = this.selectedTextArea;
            this.saveTextBlock('billDefinition');
        },
        saveBillVendorTextBlock() {
            this.billVendorTextBlock = this.selectedTextArea;
            this.saveTextBlock('billVendor');
        },
        saveBillPrivateNoteTextBlock() {
            this.billPrivateNoteTextBlock = this.selectedTextArea;
            this.saveTextBlock('billPrivateNote');
        },
        saveBillInvoiceNoteTextBlock() {
            this.billInvoiceNoteTextBlock = this.selectedTextArea;
            this.saveTextBlock('billInvoiceNote');
        },
        async saveTextBlock(textBlockType) {
            const result = await api.post(this.$store, `billdefinition/savetextblock`, {
                id: this.id,
                textBlockType,
                selectedTextArea: this.selectedTextArea,
            });

            this.processResultData(result);
            this.selectedTextArea = null;
        },
        saveBillDefinitionIsPaymentReference() {
            this.billDefinitionIsDetectedField('paymentReference');
        },
        async billDefinitionIsDetectedField(billProfileMatchingFieldName) {
            const result = await api.post(this.$store, `billdefinition/billprofilematchingfieldname`, {
                id: this.id,
                billProfileMatchingFieldName,
            });

            this.processResultData(result);
        },
        async interpretBill(formModel) {
            console.log('interpretBill formModel', formModel);
            await this.$store.dispatch('setLoading', 'Interpreting bill data');
            const result = await api.post(this.$store, `billdefinition/interpretbill`, api.formDataToObject(formModel));

            this.processResultData(result);
            await this.$store.dispatch('setLoading');
        },
        textBlockSelected(selectedTextArea) {
            console.log('textBlockSelected', selectedTextArea);
            this.selectedTextArea = selectedTextArea;
        },
        getButtons(buttons) {
            if (!buttons) return [];
            return buttons.map((oneButton) => ({
                ...oneButton,
                onClick: this.clickButton(oneButton.clickFunctionName),
                clickFunctionName: undefined,
            }));
        },
        async loadData() {
            const result = await api.get(this.$store, `billdefinition/${this.id}?isLoadPageUrls=true`);
            this.processResultData(result);
        },
        async init() {
            console.log('init');
            await this.loadData();
        },
    },
    async mounted() {
        this.id = this.$route.params.id;
        await this.init();
    },
};
</script>
