<template>
    <v-container style="min-width: 600px; overflow: scroll" class="d-flex">
        <v-card>
            <v-card-text class="d-flex flex-column">
                <div class="mx-auto">
                    <btn icon-code="mdi-minus" @click="selectPage(-1)" size="small" />
                    Page {{ currentPageNumber }} / {{ images.length }}
                    <btn icon-code="mdi-plus" @click="selectPage(1)" size="small" />
                </div>
                <div class="d-flex">
                    <v-icon class="mb-3">mdi-magnify</v-icon>
                    <v-slider v-model="scale" class="ma-3" min="20" max="150" />
                    {{ scale }}
                </div>
                <v-simple-table dense>
                    <thead>
                        <tr>
                            <th class="text-left">Field Name</th>
                            <th class="text-left">Page</th>
                            <th class="text-left">Value</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="dataField in dataFields" :key="`field${dataField.fieldName}`">
                            <td>{{ fields[dataField.fieldName] }}</td>
                            <td>{{ dataField.pageIndex + 1 }}</td>
                            <td>{{ dataField.text }}</td>
                        </tr>
                        <tr
                            v-for="specialField in specialFields"
                            class="blue--text"
                            :key="`specialField${specialField.fieldName}`"
                        >
                            <td>{{ specialField.fieldName }}</td>
                            <td>{{ specialField.pageIndex + 1 }}</td>
                            <td>{{ specialField.text }}</td>
                        </tr>
                    </tbody>
                </v-simple-table>
                <div>
                    <v-btn-toggle class="ma-1" v-model="selectMethod">
                        <v-btn
                            v-for="oneSelectMethod in selectMethods"
                            :value="oneSelectMethod"
                            :key="`selectMethod${oneSelectMethod}`"
                            small
                            >{{ oneSelectMethod }}</v-btn
                        >
                    </v-btn-toggle>
                </div>
            </v-card-text>
        </v-card>

        <canvas class="canvas" :width="canvasWidth" :height="canvasHeight" ref="pdfCanvas" />
    </v-container>
</template>

<script>
const fields = {
    invoiceNumber: 'Invoice#',
    invoiceDate: 'Date',
    totalAmount: 'Total',
    dueDate: 'Due',
    abn: 'A.B.N.',
    paymentReference: 'Payment Ref.',
};

export default {
    name: 'BillCanvas',
    props: {
        pageUrls: Array,
        pages: Array,
        billProfileTextBlock: Object,
        billDefinitionTextBlock: Object,
        billVendorTextBlock: Object,
        billPrivateNoteTextBlock: Object,
        billInvoiceNoteTextBlock: Object,
        dataFields: Array,
        saveCounter: Number,
    },
    data: () => ({
        canvasWidth: 0,
        canvasHeight: 0,
        currentPageNumber: 1,
        images: [],
        scale: 40,
        // scaleOptions: [33, 66, 100],
        selectMethod: 'lines',
        selectMethods: ['words', 'lines', 'paragraphs', 'blocks'],
        selectedTextBlock: null,
        clickedCoordinates: '',
        fields,
        specialFields: [],
        previousSaveCounter: null,
    }),
    methods: {
        selectPage(movePages) {
            const newPageNumber = this.currentPageNumber + movePages;
            if (newPageNumber >= 1 && newPageNumber <= this.images.length) {
                this.currentPageNumber = newPageNumber;
                this.drawItems();
            }
        },
        async preloadImages() {
            this.images = await Promise.all(
                this.pageUrls.map((onePageUrl) => {
                    const prom = new Promise((resolve, reject) => {
                        const image = new Image();
                        image.src = onePageUrl.downloadUrl;
                        image.onload = function () {
                            resolve(image);
                        };
                    });
                    return prom;
                }),
            );
        },
        mouseDown(e) {
            this.clickedCoordinates = `${Math.floor(e.offsetX / (this.scale / 100))},${Math.floor(
                e.offsetY / (this.scale / 100),
            )}`;
        },
        selectTextBlock() {
            if (!this.clickedCoordinates) return;

            const [x, y] = this.clickedCoordinates.split(',');

            const coordinates = { x, y };

            const currentPage = this.pages[this.currentPageNumber - 1];
            const textBlocks = currentPage[this.selectMethod];
            const newSelectedTextBlock = textBlocks.find(
                (oneTextBlock) =>
                    x >= oneTextBlock.vertices[0].x &&
                    y >= oneTextBlock.vertices[0].y &&
                    x <= oneTextBlock.vertices[2].x &&
                    y <= oneTextBlock.vertices[2].y,
            );

            if (
                newSelectedTextBlock &&
                (!this.selectedTextBlock ||
                    this.selectedTextBlock.vertices[0].x !== newSelectedTextBlock.vertices[0].x ||
                    this.selectedTextBlock.vertices[0].y !== newSelectedTextBlock.vertices[0].y ||
                    this.selectedTextBlock.vertices[2].x !== newSelectedTextBlock.vertices[2].x ||
                    this.selectedTextBlock.vertices[2].y !== newSelectedTextBlock.vertices[2].y)
            ) {
                this.selectedTextBlock = {
                    ...newSelectedTextBlock,
                    selectMethod: this.selectMethod,
                    coordinates,
                    pageIndex: this.currentPageNumber - 1,
                };
                this.$emit('text-block-selected', this.selectedTextBlock);
            }

            if (!newSelectedTextBlock && this.selectedTextBlock) {
                this.selectedTextBlock = null;

                this.$emit('text-block-selected', null);
            }
        },
        highlightArea(ctx, data, color) {
            if (data && data.pageIndex + 1 === this.currentPageNumber) {
                ctx.fillStyle = color;
                ctx.globalAlpha = 0.4;
                ctx.fillRect(
                    data.vertices[0].x,
                    data.vertices[0].y,
                    data.vertices[2].x - data.vertices[0].x,
                    data.vertices[2].y - data.vertices[0].y,
                );
                ctx.fillStyle = 'black';
                ctx.globalAlpha = 1.0;
            }
        },
        drawItems() {
            const { dimension } = this.pages[this.currentPageNumber - 1];
            const canvas = this.$refs['pdfCanvas'];
            canvas.width = dimension.width;
            canvas.height = dimension.height;
            canvas.style.width = Math.floor((dimension.width * this.scale) / 100) + 'px';
            canvas.style.height = Math.floor((dimension.height * this.scale) / 100) + 'px';
            const ctx = canvas.getContext('2d');
            canvas.addEventListener('mousedown', this.mouseDown, false);
            ctx.drawImage(this.images[this.currentPageNumber - 1], 0, 0);

            this.highlightArea(ctx, this.selectedTextBlock, 'yellow');
            this.highlightArea(ctx, this.billProfileTextBlock, 'purple');
            this.highlightArea(ctx, this.billDefinitionTextBlock, 'blue');
            this.highlightArea(ctx, this.billVendorTextBlock, 'green');
            this.highlightArea(ctx, this.billPrivateNoteTextBlock, 'orange');
            this.highlightArea(ctx, this.billInvoiceNoteTextBlock, 'cyan');
            console.log('billPrivatenote draww', this.billPrivateNoteTextBlock);
            if (this.dataFields && this.dataFields.length) {
                this.dataFields.map((oneDataField) => this.highlightArea(ctx, oneDataField, '#FF3366'));
            }
        },
        getFriendlyFieldName(fieldName) {
            return fieldName;
        },
        buildSpecialFields() {
            const buildField = (fieldName, textBlock) => {
                if (!textBlock) return null;
                return { fieldName, ...textBlock };
            };
            this.specialFields = [
                buildField('Bill Profile', this.billProfileTextBlock),
                buildField('Bill Definition', this.billDefinitionTextBlock),
                buildField('Vendor', this.billVendorTextBlock),
                buildField('Private Note', this.billPrivateNoteTextBlock),
                buildField('Invoice Note', this.billInvoiceNoteTextBlock),
            ].filter((oneField) => oneField);
        },
    },
    watch: {
        drawText() {
            this.drawItems();
        },
        scale() {
            this.drawItems();
        },
        clickedCoordinates() {
            this.selectTextBlock();
            this.drawItems();
        },
    },
    updated() {
        console.log('billcanvas updated', this.previousSaveCounter, this.saveCounter);
        this.selectedTextBlock = null;
        if (this.previousSaveCounter !== this.saveCounter) {
            this.buildSpecialFields();
            this.previousSaveCounter = this.saveCounter;
        }
        this.drawItems();
    },
    async mounted() {
        this.previousSaveCounter = this.saveCounter;
        await this.preloadImages();
        this.buildSpecialFields();
        this.drawItems();
    },
};
</script>

<style scoped>
.canvas {
    background-position: center;
    color: blue;
    border: 0;
    cursor: crosshair;
}
</style>
